var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12"
  }, [!_vm.data.contacts.length ? _c('div', {
    staticClass: "__empty__state"
  }, [_c('div', {
    staticClass: "__nodata_messages"
  }, [_vm._m(0), _c('div', {
    staticClass: "no_message_message"
  }, [_c('h3', {
    staticClass: "__nodata__header text-center"
  }, [_vm._v(_vm._s(_vm.data.message))])])])]) : _vm._e(), _vm.contacts.length ? _c('div', {
    staticClass: "__all__messages"
  }, [_c('div', {
    staticClass: "contact_table"
  }, [_c('el-table', {
    ref: "singleTable",
    staticStyle: {
      "width": "100%",
      "min-width": "100vw"
    },
    attrs: {
      "header-cell-style": {
        background: 'rgba(212, 216, 226, 0.1)'
      },
      "data": _vm.contacts,
      "height": "60vh",
      "width": "100%"
    }
  }, [_c('el-table-column', {
    attrs: {
      "width": "25%"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.firstname) + " " + _vm._s(scope.row.lastname) + " ")];
      }
    }], null, false, 2677487968)
  }), _c('el-table-column', {
    attrs: {
      "prop": "phone",
      "label": "Phone",
      "width": "100%"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "email",
      "label": "Email",
      "width": "100%"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "date_of_birth",
      "label": "Date of Birth",
      "width": "100%"
    }
  })], 1), _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin-top": "2em"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "page-size": _vm.pageSize,
      "total": _vm.total
    },
    on: {
      "current-change": _vm.changeCurrent
    }
  })], 1)], 1)]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "_no_message_header"
  }, [_c('div', {
    staticClass: "table-responsive-sm"
  }, [_c('table', {}, [_c('thead', {
    staticClass: "__mnotify__table__header"
  }, [_c('tr', [_c('th', {
    staticClass: ""
  }, [_vm._v("Name")]), _c('th', {}, [_vm._v("Mobile")])])])])])]);
}];
export { render, staticRenderFns };