export default {
  props: {
    data: {
      required: true,
      type: Object
    },
    contacts: {
      type: Array,
      required: true
    },
    page: {
      required: true,
      type: Number
    },
    pageSize: {
      required: true,
      type: Number
    },
    total: {
      required: true,
      type: Number
    },
    handleCurrentChange: {
      type: Function,
      required: true
    }
  },
  methods: {
    changeCurrent() {
      this.handleCurrentChange();
    }
  }
};