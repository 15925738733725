import store from "@/state/store.js";
import SmsMessages from "./smsmessages.vue";
import VoiceMessages from "./voicesms.vue";
import BirthdayToday from "./BirthdayToday";
import BirthdayThisMonth from "./BirthDaysThisMonth";
export default {
  name: "birthday-settings",
  components: {
    SmsMessages,
    VoiceMessages,
    BirthdayToday,
    BirthdayThisMonth
  },
  data() {
    return {
      loading: false,
      search: "",
      currentComponent: "BirthdayToday",
      today: [],
      thisMonth: [],
      page: 1,
      pageSize: 20,
      total: 0
    };
  },
  mounted() {
    this.getThisMonthCelebrants();
    this.getTodayCelebrants();
  },
  computed: {
    searchingToday() {
      if (!this.search) {
        this.total = this.today.length;
        return this.today;
      }
      this.page = 1;
      return this.today.filter(data => data.firstname.toLowerCase().includes(this.search.toLowerCase()) || data.lastname.toLowerCase().includes(this.search.toLowerCase()) || data.phone.toLowerCase().includes(this.search.toLowerCase()));
    },
    displayTodayData() {
      this.total = this.searchingToday.length;
      return this.searchingToday.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    },
    searchingThisMonth() {
      if (!this.search) {
        this.total = this.thisMonth.length;
        return this.thisMonth;
      }
      this.page = 1;
      return this.thisMonth.filter(data => data.firstname.toLowerCase().includes(this.search.toLowerCase()) || data.lastname.toLowerCase().includes(this.search.toLowerCase()) || data.phone.toLowerCase().includes(this.search.toLowerCase()));
    },
    displayThisMOnthData() {
      this.total = this.searchingThisMonth.length;
      return this.searchingThisMonth.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    async getThisMonthCelebrants() {
      try {
        const {
          data
        } = await store.dispatch("birthday/getThisMonthCelebrants");
        this.thisMonth = data;
      } catch (error) {}
    },
    async getTodayCelebrants() {
      try {
        this.loading = true;
        const {
          data
        } = await store.dispatch("birthday/getTodayCelebrants");
        this.today = data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    toggleCurrentComponent() {
      if (this.currentComponent == "BirthdayToday") {
        this.currentComponent = "BirthdayThisMonth";
      } else {
        this.currentComponent = "BirthdayToday";
      }
    }
  }
};